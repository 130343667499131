import styled from 'styled-components';
import media from 'styled-media-query';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-clip: border-box;
  border-radius: 0.25rem;
  min-width: 304px;

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
`;

const Content = styled.div`
  height: 364px;
  padding: 5rem 1.25rem 1.25rem 1.25rem;
  border-radius: 1rem;
  position: relative;
  margin-top: -60px;
  background-color: rgb(255, 255, 255);
  color: #26546d;
  font-size: 16px;
  text-align: left;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);

  ${media.lessThan('medium')`
    ul {
      padding-left: 32px;
    }
  `}
`;

const Image = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: contain;
  align-self: center;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #949baa29;
  background-color: #fff;
`;

const Title = styled.h4`
  font-size: 20px;
  padding-bottom: 20px;
  font-weight: bold;
  color: #186492;
  letter-spacing: 0.72px;
  text-align: center;
`;

export { Card, Content, Image, Title };
