import React from 'react';
import Card from './Card';
import Title from '../Title';
import Slider from '../Slider';

// import S from './styled';

const plans = [
  {
    title: 'Bradesco',
    image: '/plans/bradesco.png',
    coverage: [
      'Terapia ABA',
      'Psicologia',
      'Fonoaudiologia',
      'Audiometria',
      'Impedanciometria',
      'Avaliação de Processamento Auditivo Central(PAC)',
      'Avaliação Neuropsicológica',
    ],
  },
  {
    title: 'Mediservice',
    image: '/plans/mediservice.png',
    coverage: [
      'Terapia ABA',
      'Psicologia',
      'Fonoaudiologia',
      'Audiometria',
      'Impedanciometria',
      'Avaliação de Processamento Auditivo Central(PAC)',
      'Avaliação Neuropsicológica',
    ],
  },
  {
    title: 'Cassi',
    image: '/plans/cassi.jpg',
    coverage: [
      'Fonoaudiologia',
      'Psicologia',
      'Audiometria',
      'Impedanciometria',
      'Avaliação de Processamento Auditivo Central',
    ],
  },
  {
    title: 'Vivest',
    image: '/plans/vivest.png',
    coverage: ['Psicopedagogia', 'Psicomotricidade'],
  },
  {
    title: 'Geap',
    image: '/plans/geap.jpg',
    coverage: [
      'Fonoaudiologia',
      'Psicologia',
      'Audiometria',
      'Impedanciometria',
      'Avaliação de Processamento Auditivo Central',
      'Terapia Ocupacional',
    ],
  },
  {
    title: 'Gama',
    image: '/plans/gama.jpg',
    coverage: ['Fonoaudiologia', 'Psicologia', 'Audiometria', 'Impedanciometria'],
  },
  {
    title: 'Gndi',
    image: '/plans/notredame.jpg',
    coverage: ['Terapia ABA', 'Psicopedagogia', 'Avaliação Neuropsicológica'],
  },
  {
    title: 'Postal Saúde',
    image: '/plans/postal.jpg',
    coverage: [
      'Psicologia',
      'Fonoaudiologia',
      'Audiometria',
      'Impedânciometria',
      'Avaliação do Processamento Auditivo Central (PAC)',
    ],
  },
  {
    title: 'EVO Saúde',
    image: '/plans/evo.jpg',
    coverage: [
      'Psicologia',
      'Fonoaudiologia',
      'Audiometria',
      'Impedanciometria',
      'Avaliação de Processamento Auditivo Central',
      'Terapia Em Cabine',
    ],
  },
  {
    title: 'Doutor 123',
    image: '/plans/doutor123.jpg',
    coverage: [
      'Psicologia',
      'Terapia Ocupacional',
      'Terapia Em Cabine Acústica',
      'Orientação Vocacional',
      'Audiometria',
      'Impedanciometria',
    ],
  },
  {
    title: 'CAASP',
    image: '/plans/caasp.jpg',
    coverage: ['Fonoaudiologia', 'Psicologia'],
  },
];

const Plans = () => (
  <section id="plans">
    <Title color="#186492" text="Planos de Saúde" />
    <Slider>
      {plans.map(({ title, image, coverage }) => (
        <Card key={title} title={title} items={coverage} image={image} />
      ))}
    </Slider>
  </section>
);

export default Plans;
