export default {
  audiometria: {
    title: 'Audiometria',
    images: ['/specialities/audiometria.jpg'],
    text: `É um exame auditivo que serve para avaliar a capacidade auditiva da pessoa na interpretação de sons e palavras. Existem dois tipos principais do exame: a tonal, que nos permite saber o limiar mínimo de cada indivíduo, enquanto a vocal foca na compreensão de palavras. É importante fazer esse exame periodicamente em crianças e adultos. Às vezes a criança tem uma perda auditiva e por isso não compreende bem conversas, apresenta trocas na fala e na escrita. Além disso é muito comum as otites que, quando tratadas no início, não geram problemas, mas se não tratadas podem causar uma perda auditiva. O exame também é indicado para pessoas que trabalham com ruído ou até mesmo quando sentem dores, inflamações ou zumbidos. Teoricamente após os 60 anos já espera-se diminuição da audição. Nesse caso, a queixa é de ouvir mas não entender. É indicado fazer a audiometria para avaliar a necessidade do uso do aparelho auditivo e evitar o isolamento social.`,
  },
  'audiometria-ocupacional': {
    title: 'Audiometria Ocupacional',
    images: ['/specialities/audiometria-ocupacional.jpg'],
    text: `Esse exame é previsto pelas leis trabalhistas de acordo com os critérios propostos pela NR-7. Todo trabalho que exige exposição intensa ao ruído deve ser feito o exame e o acompanhamento de para evitar problemas que podem até causar a perda definitiva da audição.</br> A audiometria ocupacional deve ser realizada na admissão do funcionário, seis meses após a contratação, periodicamente, retorno ao trabalho, mudança de função e na demissão. Caso seja detectado qualquer problema auditivo no trabalhador, caberá à empresa adotar medidas que evitem o agravamento da sua audição e de suas consequências legais.`,
  },
  'avaliacao-bariatrica-etc': {
    title: 'Avaliações para vasectomia, laqueadura ou bariátrica',
    images: [
      '/specialities/avaliacao-bariatrica-etc.jpg',
      '/specialities/avaliacao-bariatrica-etc-2.jpg',
    ],
    text: `Avaliação Psicológica pré-operatória tem como objetivo analisar as condições emocionais do paciente para lidar com uma mudança de vida após a cirurgia.`,
  },
  'avaliacao-neuropsicologica': {
    title: 'Avaliação Neuropsicológica',
    images: ['/specialities/avaliacao-neuropsicologica.jpg'],
    text: `É uma avaliação que tem como objetivo mensurar e descrever o perfil de desempenho cognitivo, avaliando suspeitas de alterações cognitivas que podem ser decorrentes de desordens neurológicas e outros transtornos.`,
  },
  'avaliacao-psicossocial': {
    title: 'Avaliação Psicossocial',
    images: ['/specialities/avaliacao-psicossocial.jpg'],
    text: `A Avaliação Psicossocial é uma especialidade da Psicologia, que tem como objetivo principal compreender o funcionamento cerebral, mensurar as funções cognitivas de atenção, memória, raciocínio e emoções, levantar potenciais e habilidades adquiridas e relacionando os resultados com a queixa.</br> O Neuropsicólogo tem o importante papel de avaliar , investigar e criar hipóteses que fornecerá as informações necessárias para desenvolver um plano de tratamento juntamente com uma equipe multidisciplinar. Com base nos resultados das avaliações, o profissional que solicitou, poderá recomendar o tratamento mais adequado para cada pessoa.`,
  },
  fisioterapia: {
    title: 'Fisioterapia',
    images: ['/specialities/fisioterapia.jpg'],
    text: `A fisioterapia é uma ciência que previne e recupera as dificuldades funcionais do corpo quando existe uma alteração genética, trauma ou enfermidade. Com o tempo as técnicas de exercícios terapêuticos evoluíram e continuam em constante mudança. O objetivo da fisioterapia é preservar, manter, desenvolver e fazer a reabilitação da integridade de órgãos, sistemas ou funções, sempre buscando promover melhoria na qualidade de vida do paciente.`,
  },
  fonoaudiologia: {
    title: 'Fonoaudiologia',
    images: ['/specialities/fonoaudiologia.jpg'],
    text: `A Fonoaudiologia é a ciência que trabalha com os diferentes aspectos da comunicação humana: Linguagem oral e escrita, fala voz, audição e funções responsáveis pela deglutição, respiração e mastigação.`,
  },
  'integração-sensorial': {
    title: 'Integração Sensorial',
    images: ['/specialities/integração-sensorial.jpg'],
    text: `A Integração Sensorial é um tratamento pelo qual o cérebro organiza as informações, de modo a dar uma resposta adaptativa adequada, para organizar as sensações do próprio corpo em relação ao ambiente, desenvolvendo habilidades motoras para a atenção e concentração, que é dirigido para crianças que apresentam distúrbios de aprendizagem e portadores de disfunções neurológicas.`,
  },
  musicoterapia: {
    title: 'Musicoterapia',
    images: ['/specialities/musicoterapia.jpg'],
    text: `A utilização da música é usada para promover o processo de reabilitação, relação interpessoal e intrapessoal, aprendizagem, expressão de sentimentos, organização, melhorar habilidades motoras, entre outros. Tende à alcançar necessidades físicas, mentais e cognitivas.`,
  },
  'orientacao-vocacional': {
    title: 'Orientação Vocacional',
    images: ['/specialities/orientacao-vocacional.jpg'],
    text: `A Orientação vocacional é um processo conduzido por psicólogos, que através da análise de provas de interesses, aptidões e personalidade, dá algumas orientações sobre o percurso académico e profissional indicado ao paciente.`,
  },
  'processamento-auditivo-central': {
    title: 'Avaliação de Processamento Auditivo Central',
    images: ['/specialities/processamento-auditivo-central.jpg'],
    text: `É a capacidade que o sistema nervoso tem para interpretar as informações enviadas pela audição. Algumas das funções do processamento auditivo são: localização e lateralizacão dos sons, discriminação auditiva, reconhecimento do padrão auditivo, aspectos temporais da audição. </br> Para realizar a avaliação é necessário apresentar uma audiometria recente.`,
  },
  'psicologia-infantil': {
    title: 'Psicologia Infantil',
    images: ['/specialities/psicologia-infantil.jpg'],
    text: ``,
  },
  psicomotricidade: {
    title: 'Psicomotricidade',
    images: ['/specialities/psicomotricidade.jpg'],
    text: `É uma terapia que contribui para a formação e estruturação do esquema corporal, sendo assim uma ferramenta muito útil para tratar pacientes com doenças neurológicas. A psicomotricidade tem como objetivo principal incentivar a prática do movimento em todas as etapas da vida do paciente.`,
  },
  psicopedagogia: {
    title: 'Psicopedagogia',
    images: ['/specialities/psicopedagogia.jpg'],
    text: `A psicopedagogia entende as dificuldades e ajuda a melhorar o processo de aprendizado e o desenvolvimento de crianças e adolescentes.`,
  },
  psicoterapia: {
    title: 'Psicoterapia',
    images: ['/specialities/psicoterapia.jpg', '/specialities/psicoterapia-2.jpg'],
    text: `Vivemos em um mundo repleto de surpresas, algumas agradáveis outras nem tanto, somos quase que atropelados por mudanças a todo momento. Por causa disso precisamos nos reinventar e nos adaptar a novas realidades e aos desafios que se apresentam.</br> A psicoterapia nos leva a estar mais atento ao que sentimos e pensamos, favorecendo a tomada de decisão e consequente o nosso bem estar.`,
  },
  'terapia-denver': {
    title: 'Terapia Denver',
    images: ['/specialities/terapia-denver.jpg'],
    text: `Modelo Denver Autismo tem como objetivo estimular, através de brincadeiras, a motivação das crianças e seguir a liderança delas, na maior parte das vezes. É indicado para crianças de até 5 anos de idade.</br> Os primeiros sinais comportamentais do autismo podem ser observáveis quando a criança ainda é bebê, logo, quanto mais cedo a intervenção se iniciar, menores serão as dificuldades decorrentes do autismo. As pesquisam apontam que a estimulação precoce é melhor por causa da capacidade do cérebro de criar estruturas e ramificações neuronais. A reabilitação de crianças com atrasos deve ser feita com terapias baseadas em metodologias comportamentais, tal como o Modelo Denver Autismo.`,
  },
  'terapia-aba': {
    title: 'Terapia ABA',
    images: ['/specialities/terapia-aba.jpg'],
    text: `Análise do Comportamento Aplicada (ABA – Applied Behavior Analysis) é a abordagem cientifica baseada no Behaviorismo, que analisa de forma objetiva comportamentos observáveis e mensuráveis, explicando a relação que ocorre entre o ambiente, comportamento e aprendizagem.</br> O foco é a mudança e/ou o ensino de comportamentos a partir da avaliação das necessidades, interesses, contexto social e familiar, e criação de um plano de intervenção individualizado em diversas áreas: habilidades sociais, acadêmicas, motoras, brincar, imitação, linguagem, etc., que é realizado a partir da criação de oportunidades e utilização de reforço positivo, de forma que o contexto de aprendizagem seja agradável ao indivíduo durante o processo.</br> São oferecidas ao indivíduo oportunidades para que ele emita o comportamento desejado, com ou sem o auxilio de dicas, em ambientes controlados ou naturais. A intervenção ocorre em várias horas semanais, sendo o aplicador responsável pelos programas durante o período em que permanece com a criança, e a família e responsáveis recebem orientações para que deem continuidade na estimulação e saibam lidar com comportamentos diversos que podem ocorrer durante o ensino.</br> Os objetivos são diminuir a emissão de comportamentos inadequados, tornando o indivíduo mais independente possível, e ensinar a lidar com situações novas a partir da generalização de comportamentos já aprendidos.`,
  },
  'terapia-de-cabine': {
    title: 'Terapia em Cabine',
    images: ['/specialities/terapia-de-cabine.jpg'],
    text: `Após exames, é possível ter um relatório e com ele saberemos as habilidades preservadas e as alteradas para sim indicarmos o melhor tratamento, que pode ser feito através de fonoterapia informal ou através de terapia acusticamente controlada, conhecida como terapia em cabine, a diferença entre elas é que a terapia em cabine é mais focada. Conseguimos controlar a quantidade de som que entra em cada orelha. 
    Com a avaliação do PAC, saberemos os tipo de alteração que precisa ser trabalhadas. A terapia em cabine costuma levar de 10 a 15 sessões.`,
  },
  'terapia-ocupacional': {
    title: 'Terapia Ocupacional',
    images: ['/specialities/terapia-ocupacional.jpg'],
    text: `É um conjunto de técnicas, métodos e ações que, por meio de atividades aplicadas com fins terapêuticos, previnem e mantém a saúde, favorecendo restauração das funções de seu corpo para que indivíduo exerça com total bem-estar seu trabalho e tenha saúde física, mental e social.`,
  },
};
