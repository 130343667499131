import styled from 'styled-components';
import media from 'styled-media-query';

const Circle = styled.div`
  background-image: url(${(props) => props.image});
  margin: 0 auto;
  height: 200px;
  width: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 24px;
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 0 0 0 0px rgba(70, 168, 255, 0.7) inset;

  &::after {
    content: '';
    background: rgba(68, 68, 68, 0.6);
    position: absolute;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &:hover {
    box-shadow: 0 0 0 15px rgba(70, 168, 255, 0.7) inset;
    transform: scale(1.1);
    &::after {
      box-shadow: 0px 3px 24px #00000050;
    }
  }

  ${media.lessThan('medium')`
    margin: 1rem auto;
  `}
`;

const TextWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 80%;
`;

const Text = styled.h3`
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
  letter-spacing: 0.86px;
  margin-bottom: 0;
`;

export { Circle, TextWrapper, Text };
