import React from 'react';
import { createGlobalStyle } from 'styled-components';
import media from 'styled-media-query';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
  }

  main {
    background-color: #fbfbfb;
  }

  section {
    margin-top: 120px;
  }

  .carousel-control-prev {
    opacity: 1;
    background: linear-gradient(90deg, rgba(0,0,0,.1) 0%, rgba(0,0,0,0) 100%);
  }

  .carousel-control-next {
    opacity: 1;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.1) 100%);
  }

  ${media.lessThan('small')`
    section {
      margin-top: 60px;
    }

    .carousel-control-prev,
    .carousel-control-next {
      display: none !important;
  `}
`;

export default function Global({ children }) {
  return (
    <>
      <GlobalStyle theme="purple" />
      {children}
    </>
  );
}
