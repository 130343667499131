import React from 'react';
import { Carousel as RCarousel } from 'react-bootstrap';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import * as S from './styled';

const CarouselItems = [
  {
    title: '',
    text: '',
    textButton: '',
    linkButton: '',
    externalLinkButton: '',
    onlyImage: true,
    externalLinkBackground: 'https://www.instagram.com/pilaresnucleosaude/',
    image: '/carousel/construa-seus-pilares.jpg',
    leftColor: '#029fc8',
    rightColor: '#029fc8',
  },
  {
    title: '',
    text: '',
    textButton: '',
    linkButton: '',
    externalLinkButton: '',
    onlyImage: true,
    externalLinkBackground: '',
    image: '/carousel/nova-unidade.jpg',
    leftColor: '#029fc8',
    rightColor: '#029fc8',
  },
  {
    title: '',
    text: '',
    textButton: '',
    externalLinkButton: '',
    onlyImage: true,
    externalLinkBackground: 'https://www.youtube.com/watch?v=pWrfNFvy5zQ',
    image: '/carousel/somos-todos-diferentes.jpg',
    leftColor: '#ffffff',
    rightColor: '#ffffff',
  },
];

const Carousel = ({ menuHeight }) => {
  const carouselHeight = `calc(100vh - ${menuHeight}px)`;

  return (
    <RCarousel id="home">
      {CarouselItems &&
        CarouselItems.map(
          ({
            title,
            text,
            textButton,
            linkButton,
            image,
            externalLinkButton,
            externalLinkBackground,
            onlyImage,
            leftColor,
            rightColor,
          }) => (
            <RCarousel.Item key={image}>
              <a
                href={externalLinkBackground}
                target={externalLinkBackground && '_blank'}
                rel="noreferrer"
              >
                <S.BackgroundColor leftColor={leftColor} rightColor={rightColor} />
                <S.BackgroundImage height={carouselHeight} image={image} onlyImage={onlyImage} />
                <S.Caption className="left-caption text-left">
                  {title && <S.Title>{title}</S.Title>}
                  {text && <p>{text}</p>}
                  {(externalLinkButton && textButton && (
                    <a href={externalLinkButton} target="_blank" rel="noreferrer">
                      <S.ButtonSlider size="lg">{textButton}</S.ButtonSlider>
                    </a>
                  )) ||
                    (linkButton && (
                      <Link to={linkButton}>
                        <S.ButtonSlider size="lg">{textButton}</S.ButtonSlider>
                      </Link>
                    ))}
                </S.Caption>
              </a>
            </RCarousel.Item>
          )
        )}
    </RCarousel>
  );
};

Carousel.propTypes = {
  menuHeight: PropTypes.number.isRequired,
};

export default Carousel;
