import styled from 'styled-components';

const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    object-fit: contain;
  }
`;

const Label = styled.div`
  color: #26546d;
  font-size: 12px;
`;

const Text = styled.div`
  display: grid;
  align-content: center;
  color: #26546d;
  font-size: 16px;
  font-weight: 600;
`;

export { Wrapper, Label, Text };
