import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import RoundedCircle from './RoundedCircle';
import Title from '../Title';

const Specialties = () => (
  <section id="units">
    <Title color="#186492" text="Unidades" />
    <Row className="mt-5">
      <Col xs="12" md="4">
        <Link to="/unidade/guarulhos">
          <RoundedCircle text="Núcleo Infantil Guarulhos" imageUrl="/units/guarulhos/0.jpg" />
        </Link>
      </Col>
      <Col xs="12" md="4">
        <Link to="/unidade/nucleo-infantil">
          <RoundedCircle text="Núcleo Infantil Penha" imageUrl="/units/nucleo-infantil/0.jpg" />
        </Link>
      </Col>
      <Col xs="12" md="4">
        <Link to="/unidade/penha">
          <RoundedCircle text="Núcleo Pilares Penha" imageUrl="/units/penha/0.jpg" active />
        </Link>
      </Col>
    </Row>
  </section>
);

export default Specialties;
