import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const Thumbnail = ({ text, imageUrl }) => (
  <S.Thumbnail image={imageUrl}>
    <S.TextWrapper>
      <S.Text>{text}</S.Text>
    </S.TextWrapper>
  </S.Thumbnail>
);

Thumbnail.propTypes = {
  text: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default Thumbnail;
