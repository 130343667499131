import React from 'react';
import { Container } from 'react-bootstrap';
import * as S from './styled';

const Footer = () => (
  <S.Content>
    <Container>
      {/* <Row>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <S.SubTitle>Mapa do Site</S.SubTitle>
            </Col>
            <Col md={6}>
              <S.SiteMapList>
                <S.SiteMapItem>Home</S.SiteMapItem>
                <S.SiteMapItem>Especialidades</S.SiteMapItem>
                <S.SiteMapItem>Corporativa</S.SiteMapItem>
              </S.SiteMapList>
            </Col>
            <Col md={6}>
              <S.SiteMapList>
                <S.SiteMapItem>Plano de Saúde</S.SiteMapItem>
                <S.SiteMapItem>Contato</S.SiteMapItem>
              </S.SiteMapList>
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 4, offset: 2 }}>
          <S.SubTitle>Contato</S.SubTitle>
          <S.Item>Visite uma de nossas unidades</S.Item>
        </Col>
      </Row> */}
      <S.FooterCopyright>
        Todos os direitos reservados <br /> © Clínica Pilares, 2024
      </S.FooterCopyright>
    </Container>
  </S.Content>
);

export default Footer;
