import React from 'react';
import * as S from './styled';

const RoundedCircle = ({ text, imageUrl, active }) => (
  <S.Circle image={imageUrl} className={active && 'active'}>
    <S.TextWrapper>
      <S.Text>{text}</S.Text>
    </S.TextWrapper>
  </S.Circle>
);

export default RoundedCircle;
