import styled from 'styled-components';
import media from 'styled-media-query';

const TitleBase = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 72px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #949baa29;
  font-size: 24px;
  margin: 20px auto 64px auto;
  letter-spacing: 0.86px;
  color: #186492;
  font-weight: bold;
  transform-style: preserve-3d;

  ${media.lessThan('small')`
    margin: 20px auto 32px auto;
  `}

  &::before {
    content: '';
    display: block;
    background-color: ${(props) => props.color};
    position: absolute;
    border-radius: 20px;
    top: -20px;
    transform: translateZ(-1px);
  }
`;

const TitleLeft = styled(TitleBase)`
  width: 182px;
  margin-left: 20px;

  &::before {
    width: 80px;
    height: 60px;
    left: -20px;
  }
`;

const TitleCenter = styled(TitleBase)`
  width: 272px;

  &::before {
    width: 190px;
    height: 80px;
  }
`;

export { TitleLeft, TitleCenter };
