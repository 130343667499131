import React from 'react';

const GoogleMaps = ({ iframeUrl }) => (
  <iframe
    style={{ height: '500px', width: '100%', marginTop: '32px', border: '0', marginBottom: '-6px' }}
    title="Maps"
    frameBorder="0"
    src={iframeUrl}
    allowFullScreen=""
  />
);

export default GoogleMaps;
