import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Thumbnail from './Thumbnail';
import Title from '../Title';
import specialtiesContent from '../../consts/specialties-content';

const Specialties = () => (
  <section id="specialties">
    <Title color="#186492" text="Serviços" />

    <Row className="no-gutters">
      {Object.keys(specialtiesContent).map((name) => (
        <Col md="3" xs="12" key={name}>
          <Link to={`/servico/${name}`}>
            <Thumbnail
              text={specialtiesContent[name].title}
              imageUrl={specialtiesContent[name].images[0]}
            />
          </Link>
        </Col>
      ))}
    </Row>
  </section>
);

export default Specialties;
