import styled from 'styled-components';
import media from 'styled-media-query';

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  margin-bottom: 16px;
`;

const Text = styled.p`
  color: #26546d;
  font-size: 20px;

  ${media.lessThan('small')`
    font-size: 16px;
  `}
`;

export { Text, Image };
