import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styled';

const Title = ({ type, color, text }) =>
  type === 'left' ? (
    <S.TitleLeft color={color}>{text}</S.TitleLeft>
  ) : (
    <S.TitleCenter color={color}>{text}</S.TitleCenter>
  );

Title.propTypes = {
  type: PropTypes.oneOf(['left', 'center']),
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Title.defaultProps = {
  type: 'center',
};

export default Title;
