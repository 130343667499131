import styled from 'styled-components';
import media from 'styled-media-query';

const Thumbnail = styled.div`
  background-image: url(${(props) => props.image});
  height: 95px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    background: rgb(0, 116, 187, 0.71);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  ${media.greaterThan('medium')`
    height: 150px;
  `}
`;

const TextWrapper = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const Text = styled.h3`
  font-size: 16px;
  letter-spacing: 0.86px;
  color: #ffffff;
  font-weight: bold;
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.6);
  ${media.greaterThan('medium')`
    font-size: 20px;
    letter-spacing: 0.43px;
  `}
`;

export { Thumbnail, TextWrapper, Text };
