import styled from 'styled-components';
import media from 'styled-media-query';
import { Button, Carousel } from 'react-bootstrap';

const BackgroundImage = styled.div`
  position: relative;
  width: 100vw;
  height: 56.25vw;
  max-height: ${({ height }) => height};
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  ${({ onlyImage }) => !onlyImage && `filter: brightness(50%) contrast(80%); `}
`;

const BackgroundColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ leftColor }) => leftColor};
  background: linear-gradient(
    90deg,
    ${({ leftColor }) => leftColor} 0%,
    ${({ leftColor }) => leftColor} 49%,
    ${({ rightColor }) => rightColor} 50%,
    ${({ rightColor }) => rightColor} 100%
  );
`;

const Caption = styled(Carousel.Caption)`
  display: grid;
  padding: 16px;
  position: absolute;
  max-height: 75vh;
  width: 35vw;
  top: 50%;
  transform: translateY(-50%);
  p {
    font-size: 22px;
  }

  ${media.lessThan('medium')`
    width: auto;
    height: fit-content;

    p {
      font-size: 20px;
    }
  `}

  ${media.lessThan('small')`
    left: 8px;
    right: 8px;  

    p {
      font-size: 18px;
    }
  `}
`;

const Title = styled.h3`
  letter-spacing: 0.62px;
  font-size: 42px;
  font-weight: bold;

  ${media.lessThan('medium')`
    font-size: 34px;
  `}

  ${media.lessThan('small')`
    font-size: 28px;
  `}
`;

const ButtonSlider = styled(Button)`
  background-color: #00aeef !important;
  border-color: transparent;
  border-radius: 0.5rem;
  min-width: 180px;
  &:hover {
    border-color: #fff;
  }
`;

export { ButtonSlider, Caption, Title, BackgroundImage, BackgroundColor };
