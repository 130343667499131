import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Title from '../Title';
import * as S from './styled';

const About = () => (
  <section id="about">
    <Title color="#186492" text="Sobre nós" />
    <Row>
      <Col xs="12" md="6">
        <S.Image src="/units/penha/0.jpg" />
      </Col>
      <Col xs="12" md="6">
        <S.Text>
          Em <b>2005</b> iniciamos nosso trabalho clínico, realizando atendimento interdisciplinar
          nas áreas de Psicologia e Fonoaudiologia. Desde então nosso compromisso é oferecer um
          serviço de qualidade, pautado no respeito da pessoa humana, na promoção da saúde e
          qualidade de vida de nossos clientes, pacientes e colaboradores.
        </S.Text>
        <S.Text>
          Ao longo destes anos esse trabalho se ampliou e em <b>2013</b> inauguramos o{' '}
          <b>Pilares - Núcleo de Psicologia e Fonoaudiologia</b> e ampliamos nosso campo de atuação
          para outras modalidades de atendimento.
        </S.Text>
      </Col>
    </Row>
  </section>
);

export default About;
