import React from 'react';
import { Row, Col } from 'react-bootstrap';
import TextIcon from '../TextIcon';
import * as S from './styled';

const Contacts = () => (
  <div id="contacts" style={{ marginTop: '64px' }}>
    <S.Header>
      <img src="/svgs/headset.svg" alt="Icon Headset" />
      <S.Title>Contatos</S.Title>
    </S.Header>
    <Row style={{ marginTop: '32px' }}>
      <Col md={{ span: 5, offset: 1 }}>
        <TextIcon
          label="Whatsapp"
          text="(11) 96075-0276"
          icon="/svgs/whatsapp.svg"
          link="https://api.whatsapp.com/send?phone=5511960750276&text=Ol%C3%A1%2C%20vim%20pelo%20site%20e%20gostaria%20de%20marcar%20uma%20consulta%20%3A)"
        />
        <TextIcon
          label="E-mail"
          text="pilares@clinicapilares.com"
          icon="/svgs/social-email.svg"
          link="mailto: pilares@clinicapilares.com"
        />
      </Col>
      <Col md={{ span: 5, offset: 1 }}>
        <TextIcon label="Telefone" text="(11) 2855-1110" icon="/svgs/phone.svg" />
        <TextIcon label="Endereço" text="Rua Santo Afonso, 210 – Penha" icon="/svgs/location.svg" />
      </Col>
    </Row>
  </div>
);

export default Contacts;
