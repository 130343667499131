/* eslint-env browser */
import React, { useState, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Global from '../components/Global';
import Menu from '../components/Menu';
import Carousel from '../components/Carousel';
import About from '../components/About';
import Plans from '../components/Plans';
import Specialties from '../components/Specialties';
import Units from '../components/Units';
import Contacts from '../components/Contacts';
import GoogleMaps from '../components/GoogleMaps';
import Footer from '../components/Footer';

const urlMapPenha =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3658.111830494582!2d-46.55089618495046!3d-23.528479884699177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5e5a731f5bb9%3A0xe28dc435fcf3db29!2sR.%20Santo%20Afonso%2C%20210%20-%20Penha%20de%20Fran%C3%A7a%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2003632-020!5e0!3m2!1spt-BR!2sbr!4v1594070350595!5m2!1spt-BR!2sbr';

const IndexPage = () => {
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useCallback((node) => {
    if (node !== null) {
      setMenuHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const openWhatsAppChat = () => {
    document.querySelector('.whatsapp-open-button').click();
  };

  const [hasClosetWhatsAppChat, setHasClosetWhatsAppChat] = useState(false);
  const handleCloseWhatsAppChat = () => {
    window.sessionStorage.setItem('hasClosedWhatsAppChat', 'true');
  };
  useEffect(() => {
    const item = window.sessionStorage.getItem('hasClosedWhatsAppChat') || 'false';
    setHasClosetWhatsAppChat(JSON.parse(item));
  }, []);

  return (
    <main style={{ marginTop: menuHeight }}>
      <title>Clínica Pilares</title>
      <Global>
        <FloatingWhatsApp
          accountName="Rose da Pilares"
          avatar="/blue_logo.jpg"
          phoneNumber="5511960750276"
          statusMessage="Online"
          placeholder="Escreva uma mensagem"
          chatMessage="Olá! Sou a Rose assistente virtual, como posso ajudar?"
          notification={!hasClosetWhatsAppChat}
          notificationSoundSrc="/notification.mp3"
          notificationSound
          notificationDelay={5}
          notificationLoop={1}
          buttonClassName="whatsapp-open-button"
          onNotification={openWhatsAppChat}
          onClose={handleCloseWhatsAppChat}
        />
        <Menu ref={menuRef} />
        <Carousel menuHeight={menuHeight} />
        <Container>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
            <Specialties />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
            <Plans />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
            <About />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
            <Units />
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce>
            <Contacts />
          </ScrollAnimation>
        </Container>
        <GoogleMaps iframeUrl={urlMapPenha} />
        <Footer />
      </Global>
    </main>
  );
};

export default IndexPage;
