import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  img {
    height: 64px;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  color: #186492;
  font-weight: bold;
  align-self: center;
`;

export { Title, Header };
