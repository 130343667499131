import React from 'react';
import * as S from './styled';

const TextIcon = ({ icon, text, label, link }) => (
  <S.Wrapper href={link} target="_blank">
    <img src={icon} alt={text} />
    <div>
      <S.Label>{label}</S.Label>
      <S.Text>{text}</S.Text>
    </div>
  </S.Wrapper>
);

export default TextIcon;
