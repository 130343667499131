import React from 'react';
import PropTypes from 'prop-types';
import * as S from './style';

const Card = ({ title, items, image }) => (
  <S.Card title={title}>
    <S.Image src={image} />
    <S.Content>
      <ul>{items && items.map((item) => <li key={item}>{item}</li>)}</ul>
    </S.Content>
  </S.Card>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string.isRequired,
};

export default Card;
