import styled from 'styled-components';
import media from 'styled-media-query';

const Content = styled.footer`
  width: 100%;
  padding: 24px 0;
  background-color: #26546d;
  ${media.lessThan('medium')`
        height: auto;
  `}
`;

const SubTitle = styled.h3`
  color: #ffffff;
  font-size: 16px;
`;

const Item = styled.p`
  color: #fff;
  font-size: 24px;
`;

const SiteMapList = styled.ul`
  list-style: none;
  color: #ffffff;
  padding: 0px;
  margin: 0px;
`;

const SiteMapItem = styled.li`
  font-size: 24px;
  margin-left: 0;
`;

const FooterCopyright = styled.div`
  color: #fff;
  font-size: 16px;
  ${media.lessThan('medium')`
        text-align: center;
    `}
`;

export { Content, SiteMapList, SiteMapItem, SubTitle, Item, FooterCopyright };
