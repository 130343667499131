import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { isMobile } from 'react-device-detect';
import './style.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const Slider = ({ children }) => (
  <Carousel
    swipeable
    draggable
    showDots
    responsive={responsive}
    ssr
    infinite
    keyBoardControl
    transitionDuration={100}
    containerClass="carousel-container"
    deviceType={isMobile}
    dotListClass="custom-dot-list-style"
  >
    {children}
  </Carousel>
);

export default Slider;
